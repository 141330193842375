
/* VAC - 3
--------------------------------------------------------------------------------------------*/

$ac-vac-items-slider: true;
$ac-vac-items-slider-fouc-height: 500px; // what needs to be the flash-of-unwanted-content height before slider has laoded.
$ac-vac-items-breakpoint: large;
$ac-vac-items-max-width: $max-content-1200;

$ac-vac-item-visual-width: 1*.6;  // can be any percentage calculation, eg 1*.8 or 1/4. The content will take the space left. Make sure the content has enough space. 
$ac-vac-item-visual-min-height: 500px; // on small the visual height will be set as 50% of the min-height
$ac-vac-item-content-inner-max-width: 500px;
$ac-vac-item-content-inner-min-width: 500px; // set to 100% if the space of the content left is smaller than the set pixels you would prefer. 
$ac-vac-item-margin-bottom: 0;
$ac-vac-item-has-border-bottom: false;
$ac-vac-item-border: $default-border;


@import '__vac_globals';


/*--------------------------------------------------------------------------------------------*/


.ac_vac_item_container {
    min-height: $ac-vac-item-visual-min-height;
    position: relative;
    flex-direction: column;
    justify-content: center;
    display: flex;
}
.ac_vac_item {
    &.v_text-right {
        @include breakpoint(medium-portrait) {
            .ac_vac_item_container {
                flex-direction: column;
                align-items: flex-end;
            }
        }
        @include breakpoint(large) {
            .ac_vac_item_content {
                padding-right: $default-padding * 3;
            }
        }
    }
    &.v_text-left {
        @include breakpoint(large) {
            .ac_vac_item_content {
                padding-left: $default-padding * 3;
            }
        }
    }
} 

.ac_vac_item_visual {
    position: absolute;
    width: 100%;
    max-width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.ac_vac_item_content {
    margin: $default-padding * 2 0;
    
}

.ac_vac_item_content_inner {
    width: 90%;
    min-width: initial;
    margin: 0 auto;
    background: $brand-white;
    box-shadow: 10px 10px 20px rgba($brand-black, .2);
    z-index: 2;
    position: relative;
    padding: $default-padding * 2;

    @include breakpoint($ac-vac-items-breakpoint) {
        width: initial;
        margin: initial;
    }
}
    