
/* AC_S_VAC - GLOBALS
--------------------------------------------------------------------------------------------*/

.ac_vac_container {
    max-width: $ac-vac-items-max-width;
    margin: 0 auto;
}

.ac_vac_item_container {
    display: flex;
    @if $ac-vac-item-has-border-bottom == true {
        padding-bottom: $ac-vac-item-margin-bottom / 2;
        margin-bottom: $ac-vac-item-margin-bottom / 2;
    } @else {
        margin-bottom: $ac-vac-item-margin-bottom;
    }
    @include breakpoint(small only) { 
        flex-wrap: wrap;
        margin-bottom: $ac-vac-item-margin-bottom / 2;
    }
    @if $ac-vac-item-has-border-bottom == true {
        border-bottom: $ac-vac-item-border;
   }
}

.ac_vac_item_visual {
    position: relative;
    width: 100%;
    height: $ac-vac-item-visual-min-height / 2;
    @include breakpoint($ac-vac-items-breakpoint) { 
        width: percentage($ac-vac-item-visual-width); 
        max-width: percentage($ac-vac-item-visual-width);
        min-height: $ac-vac-item-visual-min-height;
        height: inherit;
        
    }
}

.ac_vac_item_content {
    width: 100%;
    max-width: 100%;
    @include breakpoint($ac-vac-items-breakpoint) { 
        width: 100% - percentage($ac-vac-item-visual-width); 
        max-width: 100% - percentage($ac-vac-item-visual-width);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
.ac_vac_item_content_inner {
    max-width: $ac-vac-item-content-inner-max-width;
    padding: $default-padding;
}

.ac_vac_item {
    &.v_text-right {
        @include breakpoint(medium-portrait) {
            .ac_vac_item_container {
                flex-direction: row-reverse;
            }
        }
    }
}   

//
//      SLIDER
//


@if $ac-vac-items-slider == true {
    .ac_vac_container {
        display: block;
        z-index: inherit; // slick slider will handle z-index

        //slick fuoc fix
        max-height: $ac-vac-items-slider-fouc-height;
        overflow: hidden;
        opacity: 0;
        transition: opacity $default-transition-speed;

        // drop fouc if slick is initialized
        &.slick-initialized  {
            max-height: inherit;
            overflow: visible;
            opacity: 1;
        }
    }
}