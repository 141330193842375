/* FIXES
--------------------------------------------------------------------------------------------*/

// HERO

[data-s-type="hero-alt"] {
    background: linear-gradient(
-135deg
, #c49700, #ffd444) !important;
}

[data-s-type="hero"] .ac_hero_container {
    height:60vh;
    min-height: 302px;
}

.a_bg_brand_primary {
    background-color: $brand-primary;
}

[data-s-type="hero-alt"] .ac_hero_alt_container {
    height: 225px;
}

[data-s-type="hero"] .ac_hero_slide_content_inner{
    background-color: rgba(255,255,255,0.7);
    justify-content: left;
    padding: 20px 20px 10px 20px;
    border-radius: 6px;
    width: 450px;
    margin-left: -100px;
    margin-top:30px;
    border-bottom: 2px solid $brand-primary;
    justify-content: flex-start;

    h2, p {
     color:$brand-black !important;
    }
    .button.v_has-icon-right {
        background: $brand-primary;
    }
}

@media only screen and (max-width: 1000px ){
    [data-s-type="hero"] .ac_hero_slide_content_inner{
        margin-left: 0;
    }
}

[data-s-type="hero"] .ac_hero_slide_content_text {
    -webkit-flex: 0 1 100%; 
    -ms-flex: 0 1 100%;
    flex: 0 1 100%; 
    max-width: 100%; 
}

//IMAGE GALERY
.ac_image-gallery_item_image_container {
    height: 300px !important;
}

// REFERENCES 
[data-s-type="references"] .ac_item_image-container:before {
    background: linear-gradient(180deg, rgba(3, 31, 49, 0), rgba(3, 31, 49, 0.8))
}

[data-s-type="references"] .ac_item_image-container {
    background-color: #ffd444;
}

//SERVICE 
[data-s-id="home-services"] {
    padding:0px;
    .ac_item_content_title {
        font-size: 115%;
    }
}

@media print, screen and (max-width: 800px){
    [data-s-type="services"] .ac_item {
        height: 200px;
    }
}

@media screen and (max-width: 39.9375em){
    [data-s-type="services"] .ac_item {
        width: auto;
        max-width: initial;
        flex: 1 0 45%;
        height: 150px;

        .ac_item_icon{
            display: none;
        }
    }
}


[data-s-type="services"] .ac_item_icon svg {
    fill:$brand-primary;
}

.service-template-default {
    [data-s-type="services"] .ac_item_content_icon svg {
        stroke: $brand-black;
    }
    [data-s-type="services"] .ac_item_content_icon {
        background: $brand-light-gray;
    }

    [data-s-type="services"] .ac_item_content_title {
        color:$brand-black;
    }
    [data-s-type="services"] a.ac_item:hover .ac_item_content_title {
        color:$brand-tertiary;
    }
 
    [data-s-type="services"] a.ac_item:hover .ac_item_icon svg {
            fill: $brand-primary-darker !important;
     }

    [data-s-type="services"] a.ac_item:hover .ac_item_content_icon {
        background: $brand-primary;
    }

    [data-s-type="services"] .ac_item_content_title {
        color:$brand-black !important;
    }

    [data-s-type="services"] .ac_item {
        height:150px;
    }

    [data-s-type="services"] .ac_item_content_title {
        font-size: 115%;
    }
    
}

// TOPTASKS
.ac_toptasks_container_inner {
    a:hover, a:focus {
        color: $brand-primary-darker;
    }
}
.ac_toptasks_item {
    position: relative;
    top: 0;
    transition: top ease 0.5s;
}

.ac_toptasks_item:hover .ac_toptasks_item_container {
    background: $brand-lightest-gray;
    .ac_toptasks_item_icon {
        background: rgba(255, 255, 255, 0.5);
    }
    .ac_toptasks_item_content_title {
        color: $brand-black;
    }
    top: -5px;
}

.ac_toptasks_item_image-container {
    background-color: $brand-primary;
}

.ac_toptasks_item_icon svg {
    stroke: $brand-secondary-darker;
}

// MENU
.ac_menu-2_bar_bottom {
    border-bottom:2px solid $brand-primary;
}

.ac_menu-2_bar_top {
    background: transparent;
    //border-bottom:1px dashed $brand-light-gray;
}

.ac_menu-2_bar_top_container{
    justify-content: flex-end;

}

.ac_menu-2_main_content {
    .button {
        font-size: 18px;
    }
}

@media screen and (max-width: 49.9375em){
    .ac_menu-2_sticky{
        display: block;
    }

    

    #menu-mobile{
        list-style: none;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        li{
            display: block;
            width: 100%;
            text-align: center;
            margin: 0 0 5px 0;
            padding: 0 0 5px 0;
            border-bottom: 1px solid rgba(0,0,0,.1);
    
            a{
                text-align: center;
                display: block;
            }

            &:last-of-type{
                border-bottom: 0;
            }
        }

        .sub-menu{
            background: rgba(0,0,0,.1);
            padding: 10px 0;
            border-radius: 5px;
            li{
                border-bottom: 0;
            }
        }
    }
}

.button.v_has-icon-left .svg-container {
    margin-right: 5px;
}

[data-m-type="menu-2"] ul#menu-top a{
    color: $brand-secondary-darker;
}

[data-m-type="menu-2"] ul.menu.dropdown a:last-of-type{
    margin-right: 0.84em;
}

// LOGO 
.ac_menu-2_logo_img {
    width: 200px;
    margin-top: -10px;
    margin-bottom: -10px;
    transition: all .3s ease;
}

.ac_menu-2_logo_link {

    img {
        max-width: 150% !important;
        transform: scale(1.3) translateY(-18px);
    }
}

.ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
    width: 150px;
    transform: none;

}


// PARTNERS 
[data-s-id="home-partners"] {
    h2 {
        text-decoration-line: underline !important;
        text-underline-offset:4px;
        text-decoration-color: $brand-primary;
    }
}

h2 {
    padding-bottom: 10px;;
}

h2.section-title {
    text-decoration-line: underline !important;
    text-underline-offset:4px;
    text-decoration-color: $brand-primary;
}

.ac_heading_title > h1 {
    text-decoration-line: underline !important;
    text-underline-offset:4px;
    text-decoration-color: $brand-primary;
}

.ac_content_flex > div{
    flex: 1;
}

// FOOTER
[data-f-type="footer-1"] .ac_footer_primary {
    background: $brand-secondary-darker;
    border-top:2px solid $brand-light-gray;

    .ac_footer_primary_column{
        flex: 1 !important;
        max-width: initial !important;
    }

    .alignnone{
        width: 90%;
        float: right;
        margin: -100px 0 0 0;
    }
}

@media screen and (max-width: 49.9375em){
    [data-f-type="footer-1"] .ac_footer_primary_column:nth-child(4){
        display: none !important;
    }

    [data-f-type="footer-1"] .ac_footer_primary_column:nth-child(3){
        display: block !important;
    }
    
}



// LINKS
.main-container [data-s-type="section-content"] a {
    text-decoration: underline;
    color: $brand-secondary;
}


// NEWS 
[data-s-type="news"] a.ac_item:hover {
    .ac_item_content_copy-above {
      color:$brand-gray;
    }
    .ac_item_content_title {
        color:$brand-gray;
    }
}

[data-s-type="news"][data-s-amount="3"] .ac_item{
    display: block !important;
}

[data-s-type="news"][data-s-amount="3"] .ac_item:nth-child(1) {
     width: 30%; 
     max-width: 30%; 
}

// FORMS
input[type="radio" i] {
    padding:0 !important;
    margin:0 !important;
}

.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li {
    padding:0 !important;
    margin:0 !important;
    margin-right:10px !important;
    display:inline;
}

.gform_wrapper ul.gfield_checkbox, .gform_wrapper ul.gfield_radio {
    margin-top:-26px !important;
}