//
//      CONTAINER
//

.ac_container {
    @if $items-max-width != 100% {
        max-width: $items-max-width;
    }
    position: relative;
    z-index: 2;
    margin: 2em auto;
    display: flex;
    flex-wrap: wrap;

    @if $items-have-border-dividers == false and $items-have-padding == true {
        padding: 0 $default-padding / 2;
    }
}