
/* MENU-101_1
--------------------------------------------------------------------------------------------*/

$ac-menu-101-menu-width: 180px;
$ac-menu-101-menu-fixed: true;
$ac-menu-101-z-index: 111;
$ac-menu-101-link-hover-color: $brand-primary-darker; 
$ac-menu-101-background: $brand-white;
$ac-menu-101-padding-bottom-for-medium: 110px; // to fix the issue with overlaying fixed toptasks

// mobile
$ac-menu-101-mobile-fixed: true; 
$ac-menu-101-mobile-height: 70px;
$ac-menu-101-mobile-padding: 10px;
$ac-menu-101-mobile-svg-size: 25px;

//socials icon
$ac-menu-101-socials-icon-background: $brand-tertiary-darker;
$ac-menu-101-socials-icon-hover-background: rgba($brand-tertiary-darker, .1);
$ac-menu-101-socials-icon-color: $brand-white;
$ac-menu-101-socials-icon-hover-color:  $brand-tertiary-darker;

// mobile
$ac-menu-101-mobile-fixed: true;  
$ac-menu-101-mobile-height: 70px;
$ac-menu-101-mobile-padding: 10px;
$ac-menu-101-mobile-svg-size: 25px;
$ac-menu-101-mobile-svg-color: $brand-tertiary-darker;

// overlay
$ac-menu-101-overlay-container-background: $brand-lightest-gray;
$ac-menu-101-overlay-menu-primary-border: $default-border;
$ac-menu-101-overlay-menu-primary-text-align: center; // left or center
$ac-menu-101-overlay-menu-secondary-border: none; // $default-border;
$ac-menu-101-overlay-menu-secondary-text-align: center; // left or center

// overlay background
$ac-menu-101-overlay-backdrop-background: rgba($brand-secondary-darker, .8);

@import '__menu_101_globals';

[data-m-type="menu-101"] {

}

