
/* SECTION - MENU 5
--------------------------------------------------------------------------------------------

// for menus we use a global scope, so we can address the body aswell

*/


$ac-menu-5-transparent: false; 
$ac-menu-5-transparent-on-home: true;
$ac-menu-5-z-index: 2222;
$ac-menu-5-background: transparent; 
$ac-menu-5-gradient-before: true;
$ac-menu-5-gradient-before-color: $brand-primary-darker;
$ac-menu-5-logo-width: 100px; // height for medium up. 
$ac-menu-5-content-width: 1000px;

$ac-menu-5-main-background: transparent;
$ac-menu-5-main-content-secondary-menu-color:$default-text-color-light;

//socials icon
$ac-menu-5-socials-icon-background: $brand-white;
$ac-menu-5-socials-icon-hover-background: $brand-tertiary-darker;
$ac-menu-5-socials-icon-color:$brand-tertiary-darker;
$ac-menu-5-socials-icon-hover-color: $brand-white;

// mobile
$ac-menu-5-mobile-fixed: true;  
$ac-menu-5-mobile-height: 70px;
$ac-menu-5-mobile-padding: 10px;
$ac-menu-5-mobile-svg-size: 25px;
$ac-menu-5-mobile-svg-color: $brand-tertiary-darker;

// overlay
$ac-menu-5-overlay-container-background: $brand-lightest-gray;
$ac-menu-5-overlay-menu-primary-border: $default-border;
$ac-menu-5-overlay-menu-primary-text-align: center; // left or center
$ac-menu-5-overlay-menu-secondary-border: none; // $default-border;
$ac-menu-5-overlay-menu-secondary-text-align: center; // left or center

// overlay background
$ac-menu-5-overlay-backdrop-background: rgba($default-background-dark, .8);

@import '__menu_5_globals';


/*--------------------------------------------------------------------------------------------*/


[data-m-type="menu-5"] {

}

