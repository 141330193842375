
//
//      LIST STYLE SMALL
//


.ac_item {
    @include breakpoint(small only) {
        @if $item-list-style-on-small == true and $items-slider == false {

            @if $items-content-over-image == true {
                height: auto !important;
            }

            .ac_item_container {
                display: block;
                overflow: inherit;
                //min-height: $item-list-style-image-width + $default-padding;
                min-height: inherit;
            }
            .ac_item_image-container{
                width: $item-list-style-image-width;
                height: $item-list-style-image-width;
                position: absolute;
                left: 0;
                top: 0;
                flex-shrink: 0;
            }
            .ac_item_content {
                position: relative;
                background: transparent;
                justify-content: flex-start;
                text-align: left;
                min-height: $item-list-style-image-width + $default-padding;
            }
            @if $item-content-icon-enabled == true {
                .ac_item_content_icon {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: $item-list-style-content-icon-background;
                    width: $item-list-style-content-icon-size;
                    height: $item-list-style-content-icon-size;
                    padding: $item-list-style-content-icon-padding;
                    svg {
                        width: $item-list-style-content-icon-size - ($item-list-style-content-icon-padding * 2);
                        height: $item-list-style-content-icon-size - ($item-list-style-content-icon-padding * 2);
                        @if $svg-icon-set-type == 'fill' {
                            fill: $item-list-style-content-icon-color;
                        } @elseif $svg-icon-set-type == 'stroke' {
                            stroke: $item-list-style-content-icon-color;
                            fill: transparent;
                        }
                    }
                }
            }
            .ac_item_content_copy {
                @if $item-image-enabled == true {
                    padding: 0 0 0 $default-padding + $item-list-style-image-width;
                    //max-width: calc(100vw - (#{$default-padding} + #{$item-list-style-image-width}));
                }
                margin-bottom: $default-padding;
            }
            @if $item-content-title-enabled == true {
                .ac_item_content_title {
                    color: $item-list-style-content-title-color;
                    flex: 1;
                }
            }
            @if $item-content-copy-above-enabled == true {
                .ac_item_content_copy-above {
                    color: $item-list-style-content-copy-above-color;
                    flex: 1;
                }
            }
            @if $item-content-copy-below-enabled == true {
                .ac_item_content_copy-below {
                    color: $item-list-style-content-copy-below-color;
                    flex: 1;
                }
            }
            [data-border-bottom] {
                border-bottom: $item-border !important;
            }
        }
    }
}