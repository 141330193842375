
/* AC_MENU-1
--------------------------------------------------------------------------------------------*/

[data-m-type="menu-1"] {

    position: relative;
    background: $ac-menu-1-background;

    @include breakpoint(medium-portrait down) {
        width: 100%;
        z-index: $ac-menu-1-z-index;
        @if $ac-menu-1-mobile-fixed == true {
            position: fixed;
            box-shadow: $default-box-shadow;
        } @else {
            position: absolute;
        }
    }

    ul.menu.is-dropdown-submenu {
        margin-left: 1em;
        padding: .4em 0;
        a {
            padding: .1em 1.2em;
        }
    }

    @if $ac-menu-1-gradient-before == true {
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba($ac-menu-4-gradient-before-color, 0), rgba($ac-menu-4-gradient-before-color, 1));
            z-index: 1;
            @include breakpoint(medium-portrait down) {
                display: none;
            }
        }
    }

    @include breakpoint(medium) {
        @if $ac-menu-1-transparent == true {
            position: absolute;
            background: transparent;
            z-index: $ac-menu-1-z-index;
            width: 100%;
        }
    }
    
    a {
        white-space: nowrap;
        @include breakpoint(medium) {
            color: $default-text-color-light;
            svg {
                fill: currentColor;
            }
        }
    }
    
    .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
         border-color: currentColor transparent transparent;
    }

    &  + .main-container {
        @include breakpoint(medium-portrait down) {
            padding-top: $ac-menu-1-mobile-height;
        }
    }

}
@if $ac-menu-1-transparent-on-home == true {
    body.home {
        [data-m-type="menu-1"] {

            @include breakpoint(medium) {
                position: absolute;
                background: transparent;
                z-index: $ac-menu-1-z-index;
                width: 100%;
            }

            .ac_menu-1_content_secondary_divider {
                background: $ac-menu-1-menu-transparent-secondary-divider-color;
            }
            
           
            .ac_menu-1_sticky {
                .ac_menu-1_menu_primary > li > a,
                .ac_menu-1_menu_secondary > a {   
                    color: $ac-menu-1-menu-transparent-primary-color; 
                    svg {
                        fill: $ac-menu-1-menu-transparent-primary-color; 
                    }
                }
                
                background-color: transparent;

                 &.is-stuck {
                    background-color: $ac-menu-1-background;

                    .ac_menu-1_menu_primary > li > a,
                    .ac_menu-1_menu_secondary > a {   
                        color: $ac-menu-1-menu-primary-color; 
                        svg {
                            fill: $ac-menu-1-menu-primary-color; 
                        }
                    }
                }
            }
        }
    }
}



//
//      STICKY
//

.ac_menu-1_unsticky_container {
    width: 100%;
    height: 2px;
}
.ac_menu-1_sticky_container {
    margin: 0 auto;
    transition: height .3s ease;
}
.ac_menu-1_sticky {
    width: 100%;
    z-index:  $ac-menu-1-z-index;
    transition: background-color .3s ease;
    @if $ac-menu-1-transparent == false {
        background-color: $ac-menu-1-background;
    } @else {
        background-color: transparent;
    }
    
    &.is-stuck {
        background-color: $ac-menu-1-background;
        z-index:  $ac-menu-1-z-index;

        box-shadow: $default-box-shadow;

        //animate elements on stuck
        .ac_menu-1_logo_img {
            width: $ac-menu-1-logo-width * .7;
        }
        .ac_menu-1_content_container {
            justify-content: center;
        }
        .ac_menu-1_content_secondary {
            visibility: none;
            height: 0px;
            opacity: 0;
            overflow: hidden;
            transition: opacity .2s ease, height .2s ease, visibility 0s .2s;
        }
        .ac_menu-1_menu_primary {
            padding-bottom: 0px;
        }
        .ac_menu-1_search_container {
            display: none;
        }
        
    }
}



//
//      MENU CONTAINER
//

.ac_menu-1_container {
    position: relative;
    margin: 0 auto;
    display: flex;
    max-width: $ac-menu-1-content-width;
    @include breakpoint(medium-portrait down) {
        padding: $default-padding / 2;
    }
}


//
//     LOGO
//

.ac_menu-1_logo_container {
    padding: $default-padding 0;
    @include breakpoint(medium-portrait down) {
        padding: 0;
    }
    @include breakpoint(medium) {
        flex: 0 0 $ac-menu-1-logo-width;
        margin-right: $default-padding;
    }
    padding-left: $default-padding; // allows for padding on overflow menu
}
a.ac_menu-1_logo_link {
    will-change: contents;
    transition: opacity .3s ease;
    margin: 0;
    &:hover {
        opacity: .8;
    }
}
.ac_menu-1_logo_img {
    @include breakpoint(medium-portrait down) {
        height: $ac-menu-1-mobile-height - ( $ac-menu-1-mobile-padding * 2);
        width: initial;
    }
    width: $ac-menu-1-logo-width;
    transition: width .3s ease;
}


//
//     CONTENT 
//

.ac_menu-1_content_container {
    @include breakpoint(medium-portrait down) {
        display: none;
    }
    @include breakpoint(medium) {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;
        padding-right: $default-padding; // allows for padding on overflow menu
    }
}


//
//     CONTENT PRIMARY
//

.ac_menu-1_content_primary {
    display: flex;
    justify-content: flex-end;
}
ul.ac_menu-1_menu_primary {

    display: flex;
    padding-bottom: $default-padding;
    list-style: none;
    margin: 0;
    flex-wrap: wrap;
    justify-content: flex-end;

    // @if $ac-menu-1-menu-scroll ==  false {
    //     flex-wrap: wrap;
    //     justify-content: flex-end;
    // }

    a {
        width: 100%;
        display: inline-block;
        color: $ac-menu-1-menu-primary-color;
    }
    // first level
    & > li {
        margin-left: $default-padding;
        flex: 0 0 auto;
        & > a {
            white-space: nowrap;
        }
    }

    // second level
    ul.is-dropdown-submenu {
        list-style: none;
        margin: 0;
        padding: .4em 0;
    }

}


//
//     CONTENT SECONDARY
//

.ac_menu-1_content_secondary {
    display: flex;
    justify-content: flex-end;
    transition: opacity .3s ease, visibility 0s .6s;
}
.ac_menu-1_menu_secondary {
    display: flex;
    a {
        margin-left: $default-padding;
        text-transform: uppercase;
        font-size: 80%;
        letter-spacing: 1px;
        color: $ac-menu-1-menu-secondary-color;
        padding-top: $default-padding;
    }
}
.ac_menu-1_content_secondary_divider {
    width: 1px;
    background: $ac-menu-1-menu-secondary-divider-color;
    margin-left: $default-padding;
    transform: scaleY(1.2);
}


//
//     BTN SEARCH
//

.ac_menu-1_btn-search {
    display: inline-flex;
    align-items: center;
    svg {
        width: 1.6em;
        height: 1.6em;
    }
}



//
//     MOBILE CONTENT
//

.ac_menu-1_mobile_content {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;

    @include breakpoint(medium) {
        display: none;
    }
}
.ac_menu-1_mobile_content_divider {
    width: 1px;
    margin: 0 1em;
    background: rgba($default-background-dark, .1);
}
//
//     MOBILE BUTTONS
//

.ac_menu-1_mobile_btn {
	height: $ac-menu-1-mobile-height - ($ac-menu-1-mobile-padding * 2);
    width: $ac-menu-1-mobile-svg-size + $ac-menu-1-mobile-svg-size;
    padding: (($ac-menu-1-mobile-height - ($ac-menu-1-mobile-padding * 2) - $ac-menu-1-mobile-svg-size) / 2) $ac-menu-1-mobile-svg-size / 2;

    svg {
        width: $ac-menu-1-mobile-svg-size;
        height: $ac-menu-1-mobile-svg-size;
        fill: $default-icon-color;
    }

}


//
//     HAMBURGER ICON
//

.ac_menu-1_mobile_btn-hamburger {
    height: $ac-menu-1-mobile-height - ($ac-menu-1-mobile-padding * 2);
    width: $ac-menu-1-mobile-svg-size + $ac-menu-1-mobile-svg-size;
    padding: 12px $ac-menu-1-mobile-padding; // padding is manual

	.burger-icon {
		@include burger(25px, 2px, 4px, $default-icon-color); // width is manual
	}

	@include breakpoint(medium) {
		display: none;
	}
}
body.s_is-open_menu-mobile {
    .burger-icon {
		@include burger-to-cross;
	}
}



//
//     OVERLAY 
//

.ac_menu-1_overlay_container {
    position: fixed;
    z-index: $ac-menu-1-z-index - 1;
    width: 400px;
    height: 100%;
    top: 0;
    right: 0;
    background: $ac-menu-1-overlay-container-background;
    transform: translateX(400px);
    transition: transform .3s ease;
    padding-top: $ac-menu-1-mobile-height;

    @include breakpoint(small only) {
        width: 90vw;
        transform: translateX(100%);
    }
    @include breakpoint(medium) {
		display: none;
	}
    
}
.ac_menu-1_overlay_scroll_container {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
}
.ac_menu-1_overlay_scroll_container_inner {
    min-height: 100vh;
}
.ac_menu-1_overlay_menu_container {
    padding: $default-padding;
}
ul.ac_menu-1_overlay_menu_primary {
    list-style: none;
    margin: 0;
    @if $ac-menu-1-overlay-menu-primary-border != none {
        border-top: $ac-menu-1-overlay-menu-primary-border;
    }
    padding-bottom: 2em;

    a {
        display: block;
        text-align: $ac-menu-1-overlay-menu-primary-text-align;
    }

    > li {
        @if $ac-menu-1-overlay-menu-primary-border != none {
            border-bottom: $ac-menu-1-overlay-menu-primary-border;
        }
        > a {
            padding: $default-padding / 2 0;
        }
    }
    ul.is-accordion-submenu {
        list-style: none;
        margin-left: 1em;
        margin-bottom: 1em;
        a {
            padding: $default-padding / 4 0;
        }
    }
}

ul.ac_menu-1_overlay_menu_secondary {
    list-style: none;
    margin: 0;
    @if $ac-menu-1-overlay-menu-secondary-border != none {
        border-top: $ac-menu-1-overlay-menu-secondary-border;
    }
    a {
        display: block;
        @if $ac-menu-1-overlay-menu-secondary-border != none {
            border-bottom: $ac-menu-1-overlay-menu-secondary-border;
        }
        text-align: $ac-menu-1-overlay-menu-secondary-text-align;
        padding: $default-padding / 4 0;
    }
}


body.s_is-open_menu-mobile {
    .ac_menu-1_overlay_container {
        transform: translateX(0);
    }
    overflow: hidden;
}

//
//     OVERLAY BACKDROP
//


.ac_menu-1_overlay_backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background: $ac-menu-1-overlay-backdrop-background;
    z-index: $ac-menu-1-z-index - 2;
    height: 0;
    opacity: 0;
    transition: opacity .3s ease .3s, height 0s .6s;

    @include breakpoint(medium) {
		display: none;
	}
}
body.s_is-open_menu-mobile {
    .ac_menu-1_overlay_backdrop {
        height: 100%;
        opacity: 1;
        transition: height 0s, opacity .3s ease;
    }
}



//
//     SEARCH
//


.ac_menu-1_search_container {
    position: absolute;
    top: 0; 
    right: 0;
    width: 50%;
    transform: translateX(-2em);
    opacity: 0;
    pointer-events: none;
    transition: all .3s ease;
    @include breakpoint(medium-portrait down) {
        top: 50%;
        right: 1em;
        transform: translateY(-50%) translateX(-2em);
    }
}
body.IE10 {
    .ac_menu-1_search_container {
        display: none;
    }
}

.ac_menu-1_search_input {
    margin: 0;
}
.ac_menu-1_search_close {
    position: absolute;
    right: .7em;
    top: 0;
    width: 3em;
    height: 3em;
    padding: .7em;
    svg {
        width: 1.6em;
        height: 1.6em;
        fill: $ac-menu-1-mobile-svg-color;
    }
}

body.s_is-open_menu-search {
    &.IE10 { 
        .ac_menu-1_search_container {
            display: block;
        }
    }
    .ac_menu-1_search_container {
        transform: translateX(0) translateY(0);
        opacity: 1;
        pointer-events: auto;
        @include breakpoint(medium-portrait down) {
            transform: translateX(0) translateY(-50%);
        }
    }
}