
/* SECTION - MENU 4
--------------------------------------------------------------------------------------------

// for menus we use a global scope, so we can address the body aswell

*/


$ac-menu-4-transparent: false; 
$ac-menu-4-transparent-on-home: true;
$ac-menu-4-z-index: 2222;
$ac-menu-4-background: $brand-white; 
$ac-menu-4-gradient-before: false;
$ac-menu-4-gradient-before-color: $default-background-dark;
$ac-menu-4-logo-width: 140px; // height for medium up. 
$ac-menu-4-content-width: 1000px;

$ac-menu-4-main-background: $brand-white;
$ac-menu-4-main-content-secondary-menu-color: rgba($brand-black, .5);

$ac-menu-4-bar-bottom-enabled: true;
$ac-menu-4-bar-bottom-justify-content: space-between;
$ac-menu-4-bar-bottom-background: $brand-light-gray;

//socials icon
$ac-menu-4-socials-icon-background: $default-background-light;
$ac-menu-4-socials-icon-hover-background: $default-background-dark;
$ac-menu-4-socials-icon-color: $default-icon-color-dark;
$ac-menu-4-socials-icon-hover-color: $default-icon-color-light;

// mobile
$ac-menu-4-mobile-fixed: true;  
$ac-menu-4-mobile-height: 70px;
$ac-menu-4-mobile-padding: 10px;
$ac-menu-4-mobile-svg-size: 25px;
$ac-menu-4-mobile-svg-color: $default-icon-color;

// overlay
$ac-menu-4-overlay-container-background: $brand-lightest-gray;
$ac-menu-4-overlay-menu-primary-border: $default-border;
$ac-menu-4-overlay-menu-primary-text-align: center; // left or center
$ac-menu-4-overlay-menu-secondary-border: none; // $default-border;
$ac-menu-4-overlay-menu-secondary-text-align: center; // left or center

// overlay background
$ac-menu-4-overlay-backdrop-background: rgba($default-background-dark, .8);

@import '__menu_4_globals';


/*--------------------------------------------------------------------------------------------*/


[data-m-type="menu-4"] {

}

