
/* BRANDS - 4
--------------------------------------------------------------------------------------------*/

// items
$ac-brands-items-slider: false;
$ac-brands-items-slider-fouc-height: 100px;
$ac-brands-items-have-padding: true;
$ac-brands-items-have-border-dividers: false;
$ac-brands-items-on-small-two-items-a-row: true; // if true two items per row on small, else one item

// item
$ac-brands-item-margin-bottom: 1.4em; // 1.4em
$ac-brands-item-container-padding: $default-padding 0;
$ac-brands-item-content-padding: $default-padding; 
$ac-brands-item-content-background: rgba($default-background-dark, 0.02);
$ac-brands-item-border: $default-border; // defines the appearance, not the display
$ac-brands-item-shadow: true; 

// description
$ac-brands-items-description-enable: false;
$ac-brands-items-description-color: rgba($default-text-color-dark,.6);
$ac-brands-items-description-hover-color: $default-text-color-dark;

// logo
$ac-brands-item-logo-margin-bottom: .6em; // 1.4em
$ac-brands-item-logo-height: 100px; 
$ac-brands-item-logo-max-width: 200px;
$ac-brands-item-logo-initial-opacity: 1;
$ac-brands-item-logo-hover-opacity: .6;


@import '__brands_globals';

/*--------------------------------------------------------------------------------------------*/

@if $ac-brands-items-slider == true {
    .ac_brands_item {
        margin: $default-padding 0;
    }
}
    