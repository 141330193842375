
/* AC_menu-101
--------------------------------------------------------------------------------------------*/

[data-m-type="menu-101"] {

    width: $ac-menu-101-menu-width;
    z-index: $ac-menu-101-z-index;
    background: $ac-menu-101-background;

    @include breakpoint(medium-portrait down) {
        width: 100%;
        @if $ac-menu-101-mobile-fixed == true {
            position: fixed;
        } @else {
            position: absolute;
        }
    }
    @include breakpoint(medium) {
        
        @if $ac-menu-101-menu-fixed == true {
            position: fixed;
        } @else {
            position: relative;
        }
    }

    ul.menu.vertical,
    ul.menu.dropdown {
        list-style: none;
        margin-left: 0;
        a {
            display: block;
        }
     }
     ul.menu.is-dropdown-submenu {
         margin-left: 1em;
         padding: .4em 0;
         box-shadow: 5px 5px 10px rgba($brand-black, .1);
         a {
             padding: .1em 1.2em;
         }
     }
     ul.is-accordion-submenu {
        list-style: none;
     }

     a {
        &:hover {
            color: $ac-menu-101-link-hover-color;
        }
     }
    
     & + .main-container {
        @include breakpoint(medium-portrait down) {
            padding-top: $ac-menu-101-mobile-height;
        }
        @include breakpoint(medium) {
            padding-left: $ac-menu-101-menu-width;
        }
    }
}




//
//      MENU CONTAINER
//

.ac_menu-101_container {
   @include breakpoint(medium-portrait down) {
       display: flex;
       padding: $default-padding / 2;
       position: relative;
       z-index: $ac-menu-101-z-index;
       background: $ac-menu-101-background;
   }
}


//
//     LOGO
//

.ac_menu-101_logo_container {
    padding: 0;
}
a.ac_menu-101_logo_link {
    will-change: contents;
    transition: opacity .3s ease;
    &:hover {
        opacity: .8;
    }
}
.ac_menu-101_logo_img {

}

.ac_menu-101_logo_mobile_container {
    @include breakpoint(medium) {
        display: none;
    }
}
a.ac_menu-101_logo_mobile_link {

}
.ac_menu-101_logo_mobile_img {
    @include breakpoint(medium-portrait down) {
        max-height: $ac-menu-101-mobile-height - ( $ac-menu-101-mobile-padding * 2);
        width: initial;
    }
}

//
//     MAIN 
//
.ac_menu-101_main {
    display: flex;
    width: $ac-menu-101-menu-width;
    box-shadow: $default-box-shadow;
    @include breakpoint(medium) {
        position: absolute;
        flex-direction: column;
        height: 100vh;
        padding: $default-padding;
        justify-content: space-between;
    }
    @include breakpoint(medium only) {
        padding-bottom: $ac-menu-101-padding-bottom-for-medium;
    }
    @include breakpoint(medium-portrait down) {
        display: none;
    }
}
.ac_menu-101_main_top {

}
.ac_menu-101_main_bottom {

}
.ac_menu-101_main_content {

}

.ac_menu-101_main_menu {
    margin-top: 2em;
}


//
//     MOBILE CONTENT
//

.ac_menu-101_mobile_content {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;

    @include breakpoint(medium) {
        display: none;
    }
}
.ac_menu-101_mobile_content_divider {
    width: 1px;
    margin: 0 1em;
    background: $brand-light-gray;
}
//
//     MOBILE BUTTONS
//

.ac_menu-101_mobile_btn  {
	height: $ac-menu-101-mobile-height - ($ac-menu-101-mobile-padding * 2);
    width: $ac-menu-101-mobile-svg-size + $ac-menu-101-mobile-svg-size;
    padding: (($ac-menu-101-mobile-height - ($ac-menu-101-mobile-padding * 2) - $ac-menu-101-mobile-svg-size) / 2) $ac-menu-101-mobile-svg-size / 2;

    svg {
        width: $ac-menu-101-mobile-svg-size !important;
        height: $ac-menu-101-mobile-svg-size !important;
    }

}


//
//     HAMBURGER ICON
//

.ac_menu-101_mobile_btn-hamburger {
    height: $ac-menu-101-mobile-height - ($ac-menu-101-mobile-padding * 2);
    width: $ac-menu-101-mobile-svg-size + $ac-menu-101-mobile-svg-size;
    padding: 12px $ac-menu-101-mobile-padding; // padding is manual

	.burger-icon {
		@include burger(25px, 2px, 4px, $brand-black); // width is manual
	}

	@include breakpoint(medium) {
		display: none;
	}
}
body.s_is-open_menu-mobile {
    .burger-icon {
		@include burger-to-cross;
	}
}



//
//     OVERLAY 
//

.ac_menu-101_overlay_container {
    position: fixed;
    z-index: $ac-menu-101-z-index - 1;
    width: 400px;
    height: 100%;
    top: 0;
    right: 0;
    background: $ac-menu-101-overlay-container-background;
    transform: translateX(400px);
    transition: transform .3s ease;
    padding-top: $ac-menu-101-mobile-height;

    @include breakpoint(small only) {
        width: 90vw;
        transform: translateX(100%);
    }
    @include breakpoint(medium) {
		display: none;
	}
    
}
.ac_menu-101_overlay_scroll_container {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
}
.ac_menu-101_overlay_scroll_container_inner {
    min-height: 100vh;
}
.ac_menu-101_overlay_menu_container {
    padding: $default-padding;
}
ul.ac_menu-101_overlay_menu_primary {
    list-style: none;
    margin: 0;
    @if $ac-menu-101-overlay-menu-primary-border != none {
        border-top: $ac-menu-101-overlay-menu-primary-border;
    }
    padding-bottom: 2em;

    a {
        display: block;
        text-align: $ac-menu-101-overlay-menu-primary-text-align;
    }

    > li {
        @if $ac-menu-101-overlay-menu-primary-border != none {
            border-bottom: $ac-menu-101-overlay-menu-primary-border;
        }
        > a {
            padding: $default-padding / 2 0;
        }
    }
    ul.is-accordion-submenu {
        list-style: none;
        margin-left: 1em;
        margin-bottom: 1em;
        a {
            padding: $default-padding / 4 0;
        }
    }
}

ul.ac_menu-101_overlay_menu_secondary {
    list-style: none;
    margin: 0;
    @if $ac-menu-101-overlay-menu-secondary-border != none {
        border-top: $ac-menu-101-overlay-menu-secondary-border;
    }
    a {
        display: block;
        @if $ac-menu-101-overlay-menu-secondary-border != none {
            border-bottom: $ac-menu-101-overlay-menu-secondary-border;
        }
        text-align: $ac-menu-101-overlay-menu-secondary-text-align;
        padding: $default-padding / 4 0;
    }
}


body.s_is-open_menu-mobile {
    .ac_menu-101_overlay_container {
        transform: translateX(0);
    }
    overflow: hidden;
}

//
//     OVERLAY BACKDROP
//


.ac_menu-101_overlay_backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background: $ac-menu-101-overlay-backdrop-background;
    z-index: $ac-menu-101-z-index - 2;
    height: 0;
    opacity: 0;
    transition: opacity .3s ease .3s, height 0s .6s;

    @include breakpoint(medium) {
		display: none;
	}
}
body.s_is-open_menu-mobile {
    .ac_menu-101_overlay_backdrop {
        height: 100%;
        opacity: 1;
        transition: height 0s, opacity .3s ease;
    }
}



//
//     SEARCH
//


.ac_menu-101_search_container {
    position: absolute;
    top: 0; 
    right: 0;
    width: 50%;
    transform: translateX(2em);
    opacity: 0;
    pointer-events: none;
    transition: all .3s ease;
    @include breakpoint(medium-portrait down) {
        top: 50%;
        right: 1em;
        transform: translateY(-50%) translateX(-2em);
    }
}
body.IE10 {
    .ac_menu-101_search_container {
        display: none;
    }
}

.ac_menu-101_search_input {
    margin: 0;
}
.ac_menu-101_search_close {
   position: absolute;
    right: .7em;
    top: 0;
    width: 3em;
    height: 3em;
    padding: .7em;
    svg {
        width: 1.6em;
        height: 1.6em;
        fill: $ac-menu-101-mobile-svg-color;
    }
}

body.s_is-open_menu-search {
    &.IE10 { 
        .ac_menu-101_search_container {
            display: block;
        }
    }
    .ac_menu-101_search_container {
        transform: translateX(0) translateY(0);
        opacity: 1;
        pointer-events: auto;
        @include breakpoint(medium-portrait down) {
            transform: translateX(0) translateY(-50%);
        }
    }
}


//
//     SOCIALS
//


.ac_socials_link_icon {
    background: $ac-menu-101-socials-icon-background;
    svg {
        fill:  $ac-menu-101-socials-icon-color;
    }
    &:hover {
        @include breakpoint(large) {
            background: $ac-menu-101-socials-icon-hover-background;
            svg {
                fill: $ac-menu-101-socials-icon-hover-color;
            }
        }
    }
}